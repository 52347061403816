@import "../defaultV2-theme/variables.scss";
@import "../defaultV2-theme/mixins.scss";

.sv_window {
  position: fixed;
  bottom: calcSize(2);
  right: calcSize(2);
  border-radius: calcSize(1);
  border: 1px solid $border-inside;
  box-shadow: $shadow-large, $shadow-medium;
  background-clip: padding-box;
  z-index: 100;
  max-height: 90vh;
  overflow: auto;
  box-sizing: border-box;
}

.sv_window_content {
    border-radius: calcSize(1);
    overflow: hidden;
}

.sv_window--collapsed {
    .sv_window_header {
        height: calcSize(4);
        padding: calcSize(1) calcSize(1) calcSize(1) calcSize(2);
        border-radius: calcSize(1);
        display: flex;
        background-color: $background-dim;
    }

    .sv_window_content {
        display: none;
    }
}

.sv_window_header_title_collapsed {
    color: $foreground-dim;
    @include defaultBold;
    flex: 1;
    display: flex;
    justify-content: start;
    align-items: center;
}

.sv_window_header_description{
    color: $font-questiondescription-color;
    font-feature-settings: 'salt' on;
    @include defaultFont;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sv_window_buttons_container {
    position: absolute;
    top: calcSize(1);
    right: calcSize(1);
    display: flex;
    gap: calcSize(1);
    z-index: 1;
}

.sv_window_button {
    display: flex;
    padding: calcSize(1);
    justify-content: center;
    align-items: center;
    border-radius: calcSize(0.5);
    cursor: pointer;

    &:hover, &:active {
        background-color: $primary-light;
        svg {
            use, path {
                fill: $primary;
            }
        }
    }

    &:active {
        opacity: 0.5;
    }

    svg {
        use, path {
            fill: $foreground-dim-light;
        }
    }
}