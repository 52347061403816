#formComment .trumbowyg-box, #formComment .sun-editor {
    border: none;
}


#formComment .sun-editor .se-toolbar {
    background-color: #dedede;
    outline: 0px solid #dadada;
}



#formComment .sun-editor {
    /* background-color: rgba(0, 44, 82, 0.3); */
    background-color: transparent !important;
    color: #000 !important;
}

#formComment input#id_title {
    border-radius: 5px !important;
    border: solid 3px #FFF !important;
    padding: 15px;
    background-color: transparent;
    color: #FFF
}

#formComment input#id_submit{
    border-radius: 5px !important;
}

#formProfile input[type="number"] #formComment .sun-editor p,
#formComment .sun-editor span,
#formComment .sun-editor h1 {
    color: #000;
}

/**** ****/
#formComment .trumbowyg-button-pane::after {
    background: none;
}

#formComment .trumbowyg-button-pane {
    border-bottom: 0px;
}


.sun-editor p {
    margin-bottom: 7px !important;
    line-height: 18px !important;
}

.comment_reply_link.btn.btn-primary {
    background: rgba(0, 44, 82, 1);
}




.comment_delete_link.btn,
.comment_reply_link.btn {
    padding: 2px 5px !important;
    margin-right: 4px;
    max-height: 25px;
    max-width: 25px;
    border: none;
}

.tag-comment {
    background: rgba(0, 44, 82, .5);
    color: #FFF;
}

.bg-child {
    background: rgba(0, 44, 82, .05);

}

.bg-comments {
    background-color: rgba(236, 236, 236, 0.9) !important;
}

span.tag.user {
    background-color: rgba(0, 44, 82, .5);
    color: #FFF;

}

#commentsForm {
    width: 80%;
    margin-top: 30px;
}

#commentsContent {
    width: 85%;
}

#commentsButton {
    width: 50%;
}


#commentsButton, #commentsForm, #commentsContent {
    margin-left: auto;
    margin-right: auto;
}

#commentList {
    max-height: 87vh;
    height: 87vh;
    width: 90hw;
    margin-top: 30px;
}

#commentList {
    background: transparent;
}

#mySidenavR {
    height: 100%;
    width: 100%;
    background: rgba(0, 44, 82, 1) !important;
    position: fixed;
    font-family: "SuisseIntlMedium";
    right: 0px;
    top: 0px;
    /* overflow-y: auto; */
    z-index: 1000;
}

#myContent {
    padding: 30px;
}

.content {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}

.swal2-container {
    z-index: 9999999999999999 !important;
}


#myContent .grid {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

#myContent .grid-item {
    background: #FFF;
    box-shadow: 6px 5px 5px -1px rgba(0, 0, 0, 0.8);
    -webkit-box-shadow: 6px 5px 5px -1px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 6px 5px 5px -1px rgba(0, 0, 0, 0.8);
}

#myContent .analytics-nav-status {
    padding: 0 30px 0 30px;
}

#myContent .analytics-nav {
    padding: 0 30px 0 30px;
}

.analytics-nav-title svg {
    font-size: 20px;
    padding: 7px 15px;
    color: #FFF;
    vertical-align: -0.5em !important;
}


.analytics-nav-title span {
    color: #FFF;
    font-size: 20px;
    font-family: "SuisseIntlMedium";
    letter-spacing: 2px;
}

.btn-lg {
    padding: 0.9rem 2rem !important;
    border-radius: 3px;
    min-width: 2.75rem;
}

.tooltip.top .tooltip-inner {
    background-color: red !important;
}

.tooltip.top .tooltip-arrow {
    border-top-color: red !important;
}

.col-lg-4.text-right {
    text-align: right;
}

.tag {
    font-size: 0.75rem;
    background-color: #f0f0f2;
    border-radius: 3px;
    padding: 0 .5rem;
    line-height: 2em;
    display: -ms-inline-flexbox;
    display: inline-flex;
    cursor: default;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.66em;
    font-family: 'SuisseIntlMedium' !important;
    font-weight: 400;
    line-height: 1.1;
    color: inherit;
}

h5 {
    font-size: 0.9rem !important;
}