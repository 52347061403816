.timeline {
    border-left: 3px solid #FFF;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    background: transparent;
    margin-left: auto;
    letter-spacing: 0.2px;
    position: relative;
    line-height: 1.4em;
    font-size: 13px !important;
    font-family: "SuisseIntlMedium";
    padding: 50px;
    list-style: none;
    text-align: left;
    max-width: 80%;
    width: 80%;

}

@media (max-width: 767px) {
    .timeline {
        max-width: 98%;
        padding: 25px;
    }
}

.timeline ul.children {
    border: 1px solid red;
    padding: 0 !important;
    list-style-type: none;
}


ul.timeline>ul>ul {
    margin-left: 0 !important;
    padding-left: 0 !important;
    padding-inline-start: 0px !important;
    -webkit-padding-start: 0;
}

.timeline h1 {
    font-weight: 300;
    font-size: 1.4em;
}

.timeline h2,
.timeline h3 {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 10px;
}

.timeline .event {
    /* border-bottom: 1px dashed #e8ebf1; */
    /* padding-bottom: 25px; */
    margin-bottom: 25px;
    position: relative;
    /* border: solid 3px #FFF !important; */
}

@media (max-width: 767px) {
    .timeline .event {
        padding-top: 30px;
    }
}

.timeline .event:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
}

.timeline .event:before,
.timeline .event:after {
    position: absolute;
    display: block;
    top: 0;
}


.timeline .event:before {
    left: -237px;
    content: attr(data-date);
    text-align: right;
    font-weight: 100;
    font-size: 14px;
    min-width: 120px;
    color: #FFF;
    padding: 7px 7px;
    border-radius: 7px;
    border: solid 3px #FFF;
    margin-top: -7px;
}

@media (max-width: 767px) {
    .timeline .event:before {
        left: 0px;
        text-align: left;
    }
}

.timeline .event:after {
    -webkit-box-shadow: 0 0 0 3px #000;
    box-shadow: 0 0 0 3px #FFF;
    left: -57.8px;
    background: #fff;
    border-radius: 50%;
    height: 9px;
    width: 9px;
    content: "";
    top: 5px;
}

@media (max-width: 767px) {
    .timeline .event:after {
        left: -31.8px;
    }
}

.no-message i {
    font-size: 180px;
    color: #FFF;
}

.no-message h1 {
    font-size: 40px;
    color: #FFF;
    margin-top: 20px;
}

.no-message {
    font-weight: bold;

    padding: 200px;
    height: 400px;
    min-width: 50%;
    margin-left: auto;
    margin-right: auto;

    vertical-align: middle;
    text-align: center;
}