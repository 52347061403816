body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "SuisseIntlBold";
  src: local("SuisseIntlBold"),
   url("./fonts/SuisseIntl-Bold.ttf") format("truetype");
   font-weight: 700;
  }

@font-face {
    font-family: "SuisseIntlMedium";
    src: local("SuisseIntlMedium"),
     url("./fonts/SuisseIntl-Medium.ttf") format("truetype");
     font-weight: 500;
  }

@font-face {
    font-family: "SuisseIntlLight";
    src: local("SuisseIntlLight"),
     url("./fonts/SuisseIntl-Light.ttf") format("truetype");
     font-weight: 600;
  }

@font-face {
    font-family: "SuisseIntlRegular";
    src: local("SuisseIntlRegular"),
     url("./fonts/SuisseIntl-Regular.ttf") format("truetype");
     font-weight: 400;
    }
  